import './SearchFormApp.scss';
import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import DatepickerStandalone from './components/Forms/Datepicker/DatepickerStandalone';

function SearchFormApp() {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <DatepickerStandalone
                            updateBookingFormEl = {() => {}}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SearchFormApp;

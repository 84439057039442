import { getMonthCalendarData, renderCalendarMonth } from '../calendar';
import { updateClassesPerRange, updateFooterText, updateRangeDates } from './date-range-picker-helper';
import { addMonths, subMonths, isAfter } from 'date-fns';
import { KeyCodes } from '../../enums/keyboard';
import { DateRangePickerSettings, ISelectedRange } from '../../interfaces/date-range-picker';

/**
 * JSOcean Range Date Picker: Desktop Version
 */
class JSORangeDatePickerDesktop{

    /**
     * properties
     */
    settings: DateRangePickerSettings;
    onFinishSelection: Function;
    fromText: string;
    toText: string;
    $root: HTMLDivElement;
    $eventsRoot: HTMLElement;
    selectedRange: ISelectedRange;
    todayDate: Date;
    calendarDate: any;

    /**
     * init
     * @param {object=} settings
     * @param {{from: Date, to: Date}|null|undefined} selectedRange
     * @param {Function} onFinishSelection
     * @param {string} fromText
     * @param {String} toText
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(settings: DateRangePickerSettings, selectedRange: ISelectedRange, onFinishSelection: Function, fromText: string, toText: string, $eventsRoot?: HTMLElement) {

        this.settings = settings;
        this.onFinishSelection = onFinishSelection;
        this.fromText = fromText || '';
        this.toText = toText || '';

        this.$root = document.createElement('div');
        this.$root.className = `jso-dropdown ${this.settings.dropdownClassName} ${this.settings.animation ? 'jso-' + this.settings.animation : ''}`;
        this.$eventsRoot = $eventsRoot || this.$root;

        // selected dates
        // @ts-ignore
        this.selectedRange = selectedRange ? selectedRange : {
            from: null,
            to: null,
            columnIndex: 0 // the number of column, in which the first day (from) was selected
        };

        // dates
        this.todayDate = new Date();
        this.calendarDate = this.initCalendarDate();

        // render view and handle view events
        // this.updateView();
    }

    /**
     * init calendar date
     * @return {Date}
     */
    initCalendarDate(){

        // by default current (left) calendar month date is today month
        if(!this.selectedRange || !this.selectedRange.from) return new Date();

        // if in the previous time the dates were selected from the right column -> the left date should be one month before
        if(this.selectedRange.columnIndex === 1){
            return subMonths(this.selectedRange.from, 1);
        }

        // if from date is defined, left month is taken from the 'from' date
        return this.selectedRange.from;
    }

    // ---------- RENDER --------------

    /**
     * render view and handle view events
     */
    updateView(){

        // render date picker dropdown
        this.$root.innerHTML = this.render();

        // update day classes according to the date range
        updateClassesPerRange(this.settings, this.$root, this.selectedRange);

        // update footer text
        updateFooterText(this.settings, this.$root, this.selectedRange, this.fromText, this.toText, this.settings.dayNames3, this.settings.monthNames3);

        // init dropdown events
        this.handleDropdownEvents();
    }

    /**
     * render
     */
    render(){

        const todayDay = this.todayDate.getDate();
        const todayMonth = this.todayDate.getMonth();
        const todayYear = this.todayDate.getFullYear();

        const calendarMonth = this.calendarDate.getMonth();
        const calendarYear = this.calendarDate.getFullYear();

        // left calendar month
        // @ts-ignore
        const calendarMonthData = getMonthCalendarData(calendarMonth, calendarYear, todayDay, todayMonth, todayYear, this.settings.weekStart,
            this.settings.monthNames);
        const calendarHTML = renderCalendarMonth(calendarMonthData, {
            renderLeftArrow: isAfter(this.calendarDate, this.todayDate),
            dayAttributes: [
                {
                    key: 'data-column-index',
                    value: 0
                }
            ],
            dayNames2: this.settings.dayNames2,
            monthNames2: this.settings.monthNames2,
            weekStart: this.settings.weekStart
        });

        // right calendar month
        const nextMonthDate = addMonths(this.calendarDate, 1);
        // @ts-ignore
        const calendarMonthData2 = getMonthCalendarData(nextMonthDate.getMonth(), nextMonthDate.getFullYear(), todayDay, todayMonth, todayYear, this.settings.weekStart,
            this.settings.monthNames);
        const calendarHTML2 = renderCalendarMonth(calendarMonthData2, {
            renderRightArrow: true,
            dayAttributes: [
                {
                    key: 'data-column-index',
                    value: 1
                }
            ],
            dayNames2: this.settings.dayNames2,
            monthNames2: this.settings.monthNames2,
            weekStart: this.settings.weekStart
        });

        return `
            <div class="jso-date-range-picker-dropdown-content">
                ${calendarHTML}
                ${calendarHTML2}
            </div>
            <div class="jso-date-range-picker-dropdown-footer">
                <div class="jso-date-range-picker-footer-texts">
                    <span class="jso-date-range-picker-footer-text">${this.fromText}</span>
                    <span class="jso-date-range-picker-footer-text">${this.toText}</span>
                </div>
                <button type="button" class="jso-date-range-picker-done-btn">${this.settings.doneButtonText}</button>
            </div>
        `;
    }

    // ---------- EVENTS --------------

    /**
     * init dropdown events
     */
    handleDropdownEvents(){

        /**
         * prevent close / open when click on dropdown content
         */
        const $content = this.$root.querySelector('.jso-date-range-picker-dropdown-content');

        if($content){
            $content.addEventListener('click', evt => {
               evt.stopPropagation();
            });
        }
        
        /**
         * on calendar left arrow click
         */
        const $leftArrow = this.$root.querySelector('.jso-calendar-month-arrow-left');

        if($leftArrow){
            $leftArrow.addEventListener('click', () => {

                // set previous month
                this.calendarDate = subMonths(this.calendarDate, 1);

                // render view and handle view events
                this.updateView();
            });
        }

        /**
         * on calendar right arrow click
         */
        const $rightArrow = this.$root.querySelector('.jso-calendar-month-arrow-right');

        if($rightArrow){
            $rightArrow.addEventListener('click', () => {

                // set next month
                this.calendarDate = addMonths(this.calendarDate, 1);

                // render view and handle view events
                this.updateView();
            });
        }

        /**
         * on DONE button click
         */
        const $done = this.$root.querySelector('.jso-date-range-picker-done-btn');

        if($done){
            $done.addEventListener('click', evt => {

                evt.stopPropagation();

                // close the dropdown and pass dates
                if(this.onFinishSelection) {
                    this.onFinishSelection(this.selectedRange);
                }
            });
        }

        /**
         * on today or 'after today' day click
         */
        const $today = this.$root.querySelector('.jso-calendar-today');
        const $days = Array.from(this.$root.querySelectorAll('.jso-calendar-after-today'));

        if($today){
            $days.push($today);
        }

        /**
         * handle day events
         */
        for(let i=0; i<$days.length; i++){

            const $day = $days[i];

            /**
             * on day click
             */
            $day.addEventListener('click', (evt: Event) => {
                this.selectDay(evt);
            });

            /**
             * on day ENTER
             */
            // @ts-ignore
            $day.addEventListener('keydown', (evt: KeyboardEvent) => {

                if (evt.which === KeyCodes.ENTER || evt.keyCode === KeyCodes.ENTER) {
                    this.selectDay(evt);
                    evt.stopPropagation();
                    evt.preventDefault();
                }
            });
        }

    }

    /**
     * select a day
     * @param {Object} evt
     */
    selectDay(evt: Event){

        const $target = evt.target as HTMLElement;

        const day = Number($target.getAttribute('data-day'));
        const month = Number($target.getAttribute('data-month'));
        const year = Number($target.getAttribute('data-year'));
        const columnIndex = Number($target.getAttribute('data-column-index')) || 0;

        // update selected range
        this.updateRangeForDates(day, month, year, columnIndex);

        // update day classes according to the date range
        updateClassesPerRange(this.settings, this.$root, this.selectedRange);

        // update footer text
        updateFooterText(this.settings, this.$root, this.selectedRange, this.fromText, this.toText, this.settings.dayNames3, this.settings.monthNames3);
    }

    // -------------- RANGE ----------------------

    /**
     * update selected days range
     * @param {number} day
     * @param {number} month
     * @param {number} year
     * @param {number} columnIndex
     */
    updateRangeForDates(day: number, month: number, year: number, columnIndex: number){

        const selectedDate = new Date(year, month, day);
        const res = updateRangeDates(selectedDate, this.selectedRange);

        this.selectedRange = {
            from: res.from,
            // @ts-ignore
            to: res.to,
            columnIndex: res.isReady ? this.selectedRange.columnIndex : columnIndex
        }
    }

}

export default JSORangeDatePickerDesktop;
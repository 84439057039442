import { Datepicker } from "./Datepicker";

export default class DatepickerStandalone extends Datepicker
{
  search() {
    let formValue = this.state.bookingFormElement.bookingForm.getValue();
    formValue.push({name: 'city', value: this.state.formData.city});

    const baseUrl = process.env.REACT_APP_URL || 'https://book.makrealty.com';
    const url = new URL(baseUrl);

    url.searchParams.set('checkIn', formValue[0].value);
    url.searchParams.set('checkOut', formValue[1].value);
    url.searchParams.set('adults', formValue[2].value);
    url.searchParams.set('children', formValue[3].value);
    url.searchParams.set('city', formValue[4].value);

    this.saveFormData(formValue);

    window.location.href = url.href;
  }
}
import {differenceInCalendarDays, isBefore, isSameDay, isWithinInterval} from 'date-fns';
import {DateRangePickerSettings, ISelectedRange} from "../../interfaces/date-range-picker";
import {log} from '../common';

/**
 * JSOcean Range Date Picker: Helper
 */

/**
 * update selected days range
 * @param {Date} selectedDate
 * @param {{from: Date, to: Date}} selectedRange
 * @return {{from: Date, to: Date, isReady: boolean}}
 */
export const updateRangeDates = (selectedDate: Date, selectedRange: ISelectedRange) => {

    // from is still not selected
    if (!selectedRange.from) {
        return {
            from: selectedDate,
            to: null,
            isReady: false
        };
    }

    // from is already selected
    const isFromClickedAgain = isSameDay(selectedRange.from, selectedDate);

    // if from clicked again, do nothing
    if (isFromClickedAgain) return selectedRange;

    // if selected date is before from date?
    const isBeforeCurrentFrom = isBefore(selectedDate, selectedRange.from);

    // set new from date, to should be null
    if (isBeforeCurrentFrom) {

        return {
            from: selectedDate,
            to: null,
            isReady: false
        };
    }

    // we checked current from day and before from day, so user clicked something after from day
    if (!selectedRange.to) {

        // if to is null -> set to and we're done!
        return {
            from: selectedRange.from,
            to: selectedDate,
            isReady: true
        };
    }

    // to is not null -> update from and set 'to' to null
    return {
        from: selectedDate,
        to: null,
        isReady: false
    }
};

/**
 * update day classes according to the date range
 * @param {object} settings
 * @param {HTMLElement} $root
 * @param {{from: Date, to: Date}} selectedRange
 */
export const updateClassesPerRange = (settings: DateRangePickerSettings, $root: HTMLElement, selectedRange: ISelectedRange) => {

    const $today = $root.querySelector('.jso-calendar-today');
    const $days = Array.from($root.querySelectorAll('.jso-calendar-after-today'));

    if ($today) {
        $days.push($today);
    }

    for (let i = 0; i < $days.length; i++) {

        const $day = $days[i];

        const day = Number($day.getAttribute('data-day'));
        const month = Number($day.getAttribute('data-month'));
        const year = Number($day.getAttribute('data-year'));
        const dayDate = new Date(year, month, day);

        // @ts-ignore
        $day.classList.remove(settings.selectedDayClassName);
        // @ts-ignore
        $day.classList.remove(settings.selectedDayFromClassName);
        // @ts-ignore
        $day.classList.remove(settings.selectedDayToClassName);

        if (selectedRange.from && !selectedRange.to && isSameDay(dayDate, selectedRange.from)) {

            // the day = 'from' date
            // @ts-ignore
            $day.classList.add(settings.selectedDayFromClassName);
        }

        if (selectedRange.from && selectedRange.to) {

            const isBetween = isWithinInterval(dayDate, {start: selectedRange.from, end: selectedRange.to});

            if (!isBetween) {
                // remove class
                // @ts-ignore
                $day.classList.remove(settings.selectedDayClassName);
            } else {
                // @ts-ignore
                $day.classList.add(settings.selectedDayClassName);

                if (isSameDay(dayDate, selectedRange.from)) {
                    // the day = 'from' date
                    // @ts-ignore
                    $day.classList.add(settings.selectedDayFromClassName);
                }

                if (isSameDay(dayDate, selectedRange.to)) {
                    // the day = 'to' date
                    // @ts-ignore
                    $day.classList.add(settings.selectedDayToClassName);
                }
            }
        }
    }
};

/**
 * update footer text
 * @param {object} settings
 * @param {HTMLElement} $root
 * @param {{from: Date, to: Date}} selectedRange
 * @param {string} defaultFromText
 * @param {string} defaultToText
 * @param {array.<string>=} dayNames3 - 3-letters day names
 * @param {array.<string>=} monthNames3 - 3-letters month names
 */
export const updateFooterText = (settings: DateRangePickerSettings, $root: HTMLElement, selectedRange: ISelectedRange, defaultFromText: string, defaultToText: string, dayNames3: Array<string> | undefined, monthNames3: Array<string> | undefined) => {

    const $texts = $root.querySelectorAll('.jso-date-range-picker-footer-text');

    if ($texts && $texts.length === 2) {

        // set from / to text in the footer
        const fromText = selectedRange ? formatFromToText(settings, selectedRange.from, defaultFromText, dayNames3, monthNames3) : defaultFromText;
        const toText = selectedRange ? formatFromToText(settings, selectedRange.to, defaultToText, dayNames3, monthNames3) : defaultToText;

        $texts[0].textContent = fromText;
        $texts[1].textContent = toText;
    }

    // update nights count value if needed
    updateNightsCount(settings, $root, selectedRange);
};

/**
 * update nights count value if needed
 * @param {object} settings
 * @param {HTMLElement} $root
 * @param {{from: Date, to: Date}} selectedRange
 */
export const updateNightsCount = (settings: DateRangePickerSettings, $root: HTMLElement, selectedRange: ISelectedRange) => {

    if (!settings.nightsCountEnabled) return;

    let count = 0;

    if (selectedRange && selectedRange.from && selectedRange.to) {

        count = Math.abs(differenceInCalendarDays(selectedRange.to, selectedRange.from));
    }

    const $nightsCount = $root.querySelector(`.${settings.nightsCountClassName}`);

    if (count > 0) {

        // if night counts already exists -> just update its value
        if ($nightsCount) {
            $nightsCount.textContent = `${count} ${settings.nightsCountText}`;
        } else {

            // otherwise create it
            const $footer = $root.querySelector('.jso-date-range-picker-footer-texts');

            if ($footer) {
                // generate nights count
                const $nightsCount = document.createElement('div');
                // @ts-ignore
                $nightsCount.className = settings.nightsCountClassName;
                $nightsCount.textContent = `${count} ${settings.nightsCountText}`;
                $footer.appendChild($nightsCount);
            }
        }

    } else {

        if ($nightsCount) {

            // try to remove the element
            // @ts-ignore
            $nightsCount.parentNode.removeChild($nightsCount);
        }
    }
};

/**
 * format from / to date text
 * @param {object} settings
 * @param {Date} date - this.selectedRange.from or this.selectedRange.to
 * @param {string} defaultText
 * @param {array.<string>=} dayNames3 - 3-letters day names
 * @param {array.<string>=} monthNames3 - 3-letters month names
 */
export const formatFromToText = (settings: DateRangePickerSettings, date: Date, defaultText: string | null, dayNames3: Array<string> | undefined, monthNames3: Array<string> | undefined) => {

    console.log(date);

    if (!date) return defaultText;

    if (!dayNames3 || !Array.isArray(dayNames3) || dayNames3.length !== 7) {
        log(settings, 'dayNames3 property should contain 7 "day names" each one 3 characters long.')
        return defaultText;
    }

    if (!monthNames3 || !Array.isArray(monthNames3) || monthNames3.length !== 12) {
        log(settings, 'monthNames3 property should contain 12 "month names" each one 3 characters long.')
        return defaultText;
    }

    return `${dayNames3[date.getDay()]}, ${monthNames3[date.getMonth()]} ${date.getDate()}`; // format(date, 'EE, MMM
                                                                                             // d');
};
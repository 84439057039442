import JSORangeDatePickerDesktop from './date-range-picker-desktop';
import JSORangeDatePickerMobile from './date-range-picker-mobile';
import {dispatchEvent, getViewportSize, log} from '../common';
import {deserializeDate, serializeDate} from '../date';
import {formatFromToText} from './date-range-picker-helper';
import {appendToStorage, getFromStorage} from '../../dal/storage';
import {KeyCodes} from '../../enums/keyboard';
import {DateRangePickerSettings, ISelectedRange} from '../../interfaces/date-range-picker';

/*
 JSOcean Date Range Picker
 Usage:
 HTML Structure:

 <div id="root">
 <span class="jso-date-range-picker-text">Check-in</span>
 •
 <span class="jso-date-range-picker-text">Check-out</span>
 </div>

 JavaScript call:
 import JSORangeDatePicker from '../../jso-date-range-picker/js/jso-date-range-picker';
 ...
 this.rangeDatePicker = new JSORangeDatePicker({
 ...
 }, document.getElementById('root'), document.getElementById('root'));
 */

/**
 * date range picker defaults
 * @type {Object}
 */
export const defaults: DateRangePickerSettings = {

    // predefined dates
    // @ts-ignore
    from: null,
    // @ts-ignore
    to: null,

    // general settings
    closed: true, // true if it is closed by default

    // nights count
    nightsCountEnabled: true,
    nightsCountText: 'night stay',

    // animation settings
    animation: '', // possible values: '', 'fade', 'slide'
    duration: 300, // in milliseconds

    // storage settings
    storageType: '', // '', 'local-storage', 'session-storage', 'cookies'
    storageName: 'jso-date-range-picker', // this setting is used like key name in web storage, or like cookie name
    cookiesExpiration: -1, // cookies expiration in minutes (-1 = cookies expire when browser is closed)

    // texts and localization
    doneButtonText: 'Done', // this text appears on the 'Done' button on desktop and mobile.
    dayNames2: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    dayNames3: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNames3: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekStart: 0, // 0 for Sunday - 6 for Saturday

    // general classes
    openedClass: 'jso-date-range-picker-opened',
    closedClass: 'jso-date-range-picker-closed',
    selectedDayFromClassName: 'jso-date-range-picker-selected-day-from',
    selectedDayToClassName: 'jso-date-range-picker-selected-day-to',
    selectedDayClassName: 'jso-date-range-picker-selected-day',
    nightsCountClassName: 'jso-date-range-picker-nights-count',

    // desktop version settings and classes
    dropdownClassName: 'jso-date-range-picker-dropdown',

    // mobile version settings and classes
    mobileHeaderText: 'Quantity Selector',
    mobileBreakpoint: 768, // if viewport width <= mobileBreakpoint -> opened mobile popup instead of desktop dropdown
    mobileMonthsStep: 5, // the number of months that will be rendered after the current month before the next scroll
    popupClassName: 'jso-date-range-picker-popup',
    popupCloseButtonClass: 'jso-date-range-picker-popup-close',

    debug: false,
    onChange: () => {},
};

/**
 * JSOcean Range Date Picker
 */
class JSORangeDatePicker {

    /**
     * properties
     */
    settings: DateRangePickerSettings;
    $root: HTMLElement;
    closed: boolean;
    $eventsRoot: HTMLElement | undefined;
    selectedRange: ISelectedRange | undefined;
    fromText: string | undefined;
    toText: string | undefined;
    desktop: JSORangeDatePickerDesktop | undefined;
    mobile: any;
    $tooltip: HTMLDivElement | undefined;

    /**
     * init
     * @param {object=} userSettings
     * @param {HTMLElement} $root
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used
     *     instead
     */
    constructor(userSettings: DateRangePickerSettings, $root: HTMLElement, $eventsRoot: HTMLElement) {

        this.settings = {...defaults, ...userSettings};
        this.$root = $root;
        // @ts-ignore
        this.closed = this.settings.closed; // opened or closed by default

        if (!this.$root) {
            log(this.settings, 'Date range picker root element is not provided.');
            // it's not possible to continue
            return;
        }

        // init events root
        this.$eventsRoot = $eventsRoot || this.$root;

        // selected dates
        this.selectedRange = {
            // @ts-ignore
            from: this.settings.from,
            // @ts-ignore
            to: this.settings.to
        };

        // if storage is enabled, restore country name from the storage
        this.restoreFromStorage();

        // from / to texts
        this.fromText = '';
        this.toText = '';
        this.getFromToTexts();
        this.setFromToTexts();

        // if(this.closed){
        //     this.close();
        // }
        // else{
        //     this.open();
        // }

        // handle range date picker events
        this.handleEvents();
    }

    /**
     * check if data received from storage is valid
     * data object should exist and contain dateRangePicker object
     * at least 'from' or 'to' properties should exist
     * @param {object} data
     * @return {boolean} true = data is valid
     */
    isStorageDataValid(data: any) {

        if (!data || !data.dateRangePicker) return false;

        // if both of them doesn't exist -> not valid; if one of them exists -> valid
        if (!data.dateRangePicker.from && !data.dateRangePicker.to) return false;

        if (data.dateRangePicker.from && typeof data.dateRangePicker.from !== 'string') return false;
        if (data.dateRangePicker.to && typeof data.dateRangePicker.to !== 'string') return false;

        return true;
    }

    /**
     * if storage is enabled, restore country name from the storage
     */
    restoreFromStorage() {

        if (this.settings.storageType) {

            /*
             The result should be:
             {
             dateRangePicker: {
             from: "yyyy-MM-dd",
             to: "yyyy-MM-dd"
             },
             ... other widgets ....
             }
             */
            // @ts-ignore
            let data: any = getFromStorage(this.settings.storageType, this.settings.storageName);

            try {
                data = JSON.parse(data);
            }
            catch (err) {}

            if (!this.isStorageDataValid(data)) return;

            // 'yyyy-MM-dd'

            try {
                this.selectedRange = {
                    // @ts-ignore
                    from: data.dateRangePicker.from ? deserializeDate(data.dateRangePicker.from) : null,
                    // @ts-ignore
                    to: data.dateRangePicker.to ? deserializeDate(data.dateRangePicker.to) : null
                };
            }
            catch (ex) {}
        }
    }

    // ---------- EVENTS ---------------

    /**
     * stop events propagation
     * @param {object} evt
     */
    stopPropagation(evt: Event) {
        evt.stopPropagation();
    }

    /**
     * handle range date picker events
     */
    handleEvents() {

        /**
         * on root click -> toggle dropdown for desktop or popup for mobile
         */
        this.$root.addEventListener('click', evt => {

            evt.stopPropagation();

            if (!this.closed) {

                // close desktop dropdown or mobile popup
                this.close();
            } else {
                // open desktop dropdown or mobile popup
                this.open();
            }
        });

        /**
         * when the root is focused and user presses ENTER -> toggle the drop-down or mobile popup
         */
        this.$root.addEventListener('keydown', evt => {

            if (evt.which === KeyCodes.ENTER || evt.keyCode === KeyCodes.ENTER) {

                evt.stopPropagation();

                if (!this.closed) {

                    // close desktop dropdown or mobile popup
                    this.close();
                } else {
                    // open desktop dropdown or mobile popup
                    this.open();
                }
            }
        });

        /**
         * click outside -> close
         */
        document.addEventListener('click', () => {
            this.close();
        });
    }

    /**
     * once user is finished selection
     *
     * @param {{from: Date, to: Date}=} selectedRange
     */
    onFinishSelection(selectedRange: ISelectedRange) {

        this.selectedRange = selectedRange;

        // update dates text
        this.setFromToTexts();

        // close the dropdown / popup
        this.close();

        // if storage is enabled, save selected country name to the storage
        if (this.settings.storageType) {

            const isFromDefined = this.selectedRange && this.selectedRange.from;
            const isToDefined = this.selectedRange && this.selectedRange.to;

            // @ts-ignore
            appendToStorage(this.settings.storageType, this.settings.storageName, 'dateRangePicker', {
                from: isFromDefined ? serializeDate(this.selectedRange.from) : null,
                to: isToDefined ? serializeDate(this.selectedRange.to) : null
            }, this.settings.cookiesExpiration);
        }

        // dispatch event when selection is finished
        // @ts-ignore
        dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-onchange', {selectedRange});
        //trick for custom onchange handler
        this.settings.onChange && this.settings.onChange(selectedRange)
    }

    // -------------- OPEN / CLOSE ---------------

    /**
     * open desktop dropdown or mobile popup
     */
    open() {
        let isClosed = false;
        let eventType = 'desktop';

        console.log('opening');

        // @ts-ignore
        if (getViewportSize().width > this.settings.mobileBreakpoint) {

            isClosed = !this.desktop || this.desktop && this.desktop.$root === undefined;

            // init desktop version
            this.desktop = new JSORangeDatePickerDesktop(this.settings, <ISelectedRange>this.selectedRange, this.onFinishSelection.bind(this), <string>this.fromText, <string>this.toText);
            this.desktop.updateView();

            // append desktop version dropdown to the root
            this.$root.appendChild(this.desktop.$root);

            // prevent closing on dropdown click
            this.desktop.$root.addEventListener('click', this.stopPropagation);
        } else {
            isClosed = !this.mobile || this.mobile && this.mobile.$root === undefined;
            eventType = 'mobile';

            // init mobile version
            // @ts-ignore
            this.mobile = new JSORangeDatePickerMobile(this.settings, this.selectedRange, this.onFinishSelection.bind(this), this.fromText, this.toText);

            // append mobile version popup to the root
            this.$root.appendChild(this.mobile.$root);

            // prevent closing on mobile popup click
            this.mobile.$root.addEventListener('click', this.stopPropagation);
        }

        this.closed = false;

        // @ts-ignore
        this.$root.classList.remove(this.settings.closedClass);
        // @ts-ignore
        this.$root.classList.add(this.settings.openedClass);

        if (isClosed) {

            // dispatch event when drop-down closes
            dispatchEvent(<HTMLElement>this.$eventsRoot, 'jso-quantity-selector-opened', {type: eventType});
        }
    }

    /**
     * close helper: if animation enabled -> add class 'end' and wait till the animation has finished
     * @param {Function} callback
     */
    closeHelper(callback: Function) {

        if (this.desktop && this.desktop.$root && this.settings.animation) {

            this.desktop.$root.classList.add('jso-animation-end');

            window.setTimeout(() => {
                callback();
            }, this.settings.duration);
        } else {
            callback();
        }
    }

    /**
     * close desktop dropdown or mobile popup
     */
    close() {

        this.closed = true;

        // @ts-ignore
        this.$root.classList.remove(this.settings.openedClass);
        // @ts-ignore
        this.$root.classList.add(this.settings.closedClass)

        if (this.desktop) {

            this.closeHelper(() => {

                // @ts-ignore
                const isOpened = this.desktop.$root !== undefined;
// @ts-ignore
                if (this.desktop.$root) {
                    // @ts-ignore
                    this.desktop.$root.removeEventListener('click', this.stopPropagation);
                    // @ts-ignore
                    this.desktop.$root.parentNode.removeChild(this.desktop.$root);
                }

                this.desktop = undefined;

                if (isOpened) {

                    // dispatch event when drop-down closes
                    // @ts-ignore
                    dispatchEvent(this.$eventsRoot, 'closed', {type: 'jso-quantity-selector-desktop'});
                }
            });
        }

        if (this.mobile) {

            const isOpened = this.mobile.$root !== undefined;

            if (this.mobile.$root) {
                this.mobile.$root.removeEventListener('click', this.stopPropagation);
            }

            this.mobile.$root.parentNode.removeChild(this.mobile.$root);
            this.mobile = undefined;

            if (isOpened) {

                // dispatch event when popup closes
                // @ts-ignore
                dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-closed', {type: 'mobile'});
            }
        }
    }

    // --------------- HELPERS -------------------

    /**
     * get from / to texts
     */
    getFromToTexts() {

        const $texts = this.$root.querySelectorAll('.jso-date-range-picker-text');

        if (!$texts || $texts.length < 2) return;

        // @ts-ignore
        this.fromText = $texts[0].textContent.trim();
        // @ts-ignore
        this.toText = $texts[1].textContent.trim();
    }

    /**
     * set from / to texts
     */
    setFromToTexts() {

        const $texts = this.$root.querySelectorAll('.jso-date-range-picker-text');

        if (!this.selectedRange || !$texts || $texts.length < 2) return;
        // @ts-ignore
        $texts[0].textContent = this.selectedRange.from ? formatFromToText(this.settings, this.selectedRange.from, this.fromText, this.settings.dayNames3, this.settings.monthNames3) : this.fromText;
        // @ts-ignore
        $texts[1].textContent = this.selectedRange.to ? formatFromToText(this.settings, this.selectedRange.to, this.fromText, this.settings.dayNames3, this.settings.monthNames3) : this.toText;
    }

    // ---------------- APIs -----------------------

    /**
     * show tooltip - it may be used as validation error, but not limited
     * @param {string} msg
     * @param {string} className
     */
    showTooltip(msg: string, className: string) {

        this.$tooltip = document.createElement('div');
        this.$tooltip.className = className;
        this.$tooltip.innerHTML = msg;
        this.$root.appendChild(this.$tooltip);

        // the event is dispatched when a tooltip shows
        // @ts-ignore
        dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-tooltip-shown', {message: msg});
    }

    /**
     * hide tooltip - it may be used as validation error, but not limited
     */
    hideTooltip() {

        if (this.$tooltip && this.$tooltip.parentNode) {
            this.$tooltip.parentNode.removeChild(this.$tooltip);

            // the event is dispatched when a tooltip hides
            // @ts-ignore
            dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-tooltip-hidden', '');
        }
    }
}

export default JSORangeDatePicker;